import Pui9HttpRequests from '@Pui9Requests';

export async function disableErrorcodes(unit) {
	const controller = '/pmerrorcodes/disableErrorcodes';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		unit,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}

export async function enableErrorcodes(unit) {
	const controller = '/pmerrorcodes/enableErrorcodes';
	let data = [];

	await Pui9HttpRequests.postRequest(
		controller,
		unit,
		(response) => {
			if (response) {
				response.success = true;
				data = response;
			}
		},
		(error) => {
			if (error) {
				error.error = true;
				data = error;
			}
		}
	);
	return data;
}
