import { disableErrorcodes, enableErrorcodes } from '@/api/pmerrorcodes';

const disableErrorcode = {
	id: 'disableErrorcode',
	selectionType: 'multiple',
	label: 'pmerrorcodes.actions.disableErrorcode',
	functionality: 'UPDATE_PMERRORCODES',
	checkAvailability: function (errorcodes) {
		return errorcodes && errorcodes.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmerrorcodes.actions.disableErrorcode');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmerrorcodesid: registries[i].pmerrorcodesid
			});
		}

		const data = await disableErrorcodes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableErrorcode = {
	id: 'enableErrorcode',
	selectionType: 'multiple',
	label: 'pmerrorcodes.actions.enableErrorcode',
	functionality: 'UPDATE_PMERRORCODES',
	checkAvailability: function (errorcodes) {
		return errorcodes && errorcodes.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('pmerrorcodes.actions.enableErrorcode');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				pmerrorcodesid: registries[i].pmerrorcodesid
			});
		}

		const data = await enableErrorcodes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableErrorcode, enableErrorcode]
};
